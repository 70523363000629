import { Button } from "@adv-libs/r365-ui";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import API from "../../api/API";
import { DocumentsListAPIResponse } from "../../api/documentsAPI";
import {
  ROUTE_APPROVE_TASK,
  ROUTE_TASK,
  ROUTE_DOCUMENTS,
} from "../../app/routes";
import AdvSelect2WithBack from "../../components/AdvSelect2WithBack/AdvSelect2WithBack";
import InfiniteCardWithSearch from "../../components/InfiniteCard/InfiniteCardWithSearch";
import NoDataCard from "../../components/NoDataCard";
import { useCompanyContext } from "../../features/Companies/CompanyContext";
import useRouterXMLFilter from "../../features/XMLFilter/RouterXMLFilter";
import useI18n from "../../hooks/useI18n";
import DocumentCard from "./components/DocumentCard";
import TextField from "../../components/TextField";
import { useDebounced } from "@adv-libs/utils";

export interface MyDocumentsHistoryProps {}

const XML_FILTER_PROPERTIES = ["apr1", "apr2", "apr3", "menuo", "search"];

const MyDocumentsHistory: React.FC<MyDocumentsHistoryProps> = (props) => {
  const companyContext = useCompanyContext();
  const { t, pt } = useI18n();

  const fetchFilters = useCallback(
    async (filterXML) => {
      return await API.documents.getDocumentsFilters({
        clientDbId: companyContext.company.clientDbId,
        filterXML: filterXML,
      });
    },
    [companyContext.company.clientDbId]
  );

  const {
    filterValue,
    setFilter,
    filterXML,
    hasFilter,
    filtersData,
    confirmFilter,
    clearFilter,
    showFilters,
    setShowFilters,
    isLoading,
  } = useRouterXMLFilter({
    filterProperties: XML_FILTER_PROPERTIES,
    fetchFilters: fetchFilters,
  });

  const handleApr1FilterChange = useCallback(
    (apr1) => {
      setFilter("apr1", apr1);
    },
    [setFilter]
  );

  const handleApr2FilterChange = useCallback(
    (apr2) => {
      setFilter("apr2", apr2);
    },
    [setFilter]
  );

  const handleApr3FilterChange = useCallback(
    (apr3) => {
      setFilter("apr3", apr3);
    },
    [setFilter]
  );

  const handleMenuoFilterChange = useCallback(
    (menuo) => {
      setFilter("menuo", menuo);
    },
    [setFilter]
  );

  const handleSearchFilterChange = useCallback(
    (search) => {
      setFilter("search", [{ id: search, name: search, disabled: false }]);
    },
    [setFilter]
  );

  const debouncedSearch = useDebounced(filterValue.search, 500);

  useEffect(() => {
    confirmFilter();
  }, [debouncedSearch, confirmFilter]);

  const request = useCallback(
    async (page: number) => {
      const result = await API.documents.list({
        clientDbId: companyContext.company.clientDbId,
        filterXML: filterXML,
        page: page,
        size: 10,
      });

      return result;
    },
    [companyContext.company.clientDbId, filterXML]
  );

  const keepCache = useMemo(() => {
    return [ROUTE_DOCUMENTS.path, ROUTE_TASK.path, ROUTE_APPROVE_TASK.path];
  }, []);

  const handleFiltersToggle = useCallback(() => {
    setShowFilters((showFilters) => !showFilters);
  }, [setShowFilters]);

  const filterItemDisabled = useCallback((item) => {
    return item.disabled;
  }, []);

  const filtersContainer = useMemo(() => {
    if (!showFilters) return null;
    return (
      <FiltersStyled>
        <Filters>
          <AdvSelect2WithBack
            value={filterValue.apr1}
            onCommit={handleApr1FilterChange}
            getItems={filtersData.apr1}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Title 1")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.apr2}
            onCommit={handleApr2FilterChange}
            getItems={filtersData.apr2}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Title 2")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.apr3}
            onCommit={handleApr3FilterChange}
            getItems={filtersData.apr3}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Title 3")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
          <AdvSelect2WithBack
            value={filterValue.menuo}
            onCommit={handleMenuoFilterChange}
            getItems={filtersData.menuo}
            paginated={false}
            itemIsDisabled={filterItemDisabled}
            isClearable
            label={pt("filter", "Month Of Document")}
            multiple
            multipleCheckboxes
            showMenuConfirm
            detectPrimitive
            onValueClear={confirmFilter}
            onMenuClosed={confirmFilter}
            filter="fuzzy"
            readOnly={isLoading}
          />
        </Filters>
        <ClearFilterButton>
          <Button
            icon={"times-circle-regular"}
            minimal
            disabled={!hasFilter}
            onClick={clearFilter}
          >
            {t("Clear filters")}
          </Button>
        </ClearFilterButton>
      </FiltersStyled>
    );
  }, [
    showFilters,
    filterValue.apr1,
    filterValue.apr2,
    filterValue.apr3,
    filterValue.menuo,
    handleApr1FilterChange,
    filtersData.apr1,
    filtersData.apr2,
    filtersData.apr3,
    filtersData.menuo,
    filterItemDisabled,
    confirmFilter,
    isLoading,
    handleApr2FilterChange,
    handleApr3FilterChange,
    handleMenuoFilterChange,
    t,
    pt,
    hasFilter,
    clearFilter,
  ]);

  const searchInput = useMemo(() => {
    const value =
      filterValue.search &&
      filterValue.search.length > 0 &&
      filterValue.search[0].id
        ? filterValue.search[0].id
        : "";

    return (
      <SearchStyled>
        <TextField
          type="text"
          value={value}
          startIcon={"search-finder"}
          endIcon={"filter-xmark"}
          onEndIconClick={handleFiltersToggle}
          onCommit={handleSearchFilterChange}
          label={pt("filter", "Search")}
        />
      </SearchStyled>
    );
  }, [handleFiltersToggle, handleSearchFilterChange, filterValue.search, pt]);

  return (
    <InfiniteCardWithSearch
      showMore
      id="documents"
      keepOn={keepCache}
      header={t("Documents")}
      request={request}
      filtersContainer={filtersContainer}
      searchInput={searchInput}
      threshold={9}
      iconSrc="/icons/icon-nav-documents.svg"
      showBackButton
    >
      {(items: DocumentsListAPIResponse) => {
        if (!items.length) {
          return <NoDataCard>{t("No documents")}</NoDataCard>;
        }
        return (
          <MyDocumentsItems>
            {items.map((item, key) => {
              return <DocumentCard key={key} item={item} />;
            })}
          </MyDocumentsItems>
        );
      }}
    </InfiniteCardWithSearch>
  );
};

const SearchStyled = styled.div`
  > div {
    margin-bottom: 0;
  }

  .r365-control {
    border: 1px solid #e6e6e6;

    .r365-control__end-icon--clickable {
      background-color: #fff;
    }
  }
`;

const MyDocumentsItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  // padding: 16px 8px 0px 8px;
`;

const Filters = styled.div`
  display: grid;
  gap: 8px;

  grid-template-columns: repeat(1, 1fr);

  .r365-control-select2 {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.minScreenLength}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ClearFilterButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FiltersStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

export default MyDocumentsHistory;
